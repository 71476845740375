<template>
	<div>
		<div
			class="text-h6 text-md-h5"
			v-html="
				$t('app.home.more_details', {
					weresearch: $t('app.weresearch_with_style'),
				})
			"
		></div>

		<v-container v-if="userLang != 'en'" class="text-center mt-10">
			<template v-for="(item, index) in people">
				<v-card
					class="wr_indigo_2 home-features-card-wr elevation-4 d-inline-block mx-2 relative-pos-wr"
					width="200"
					align="start"
					:key="index"
				>
					<v-card-text align="center" class="pb-0">
						<v-img
							contain
							:width="imageWidth"
							:src="require('@/assets/images/profile.png')"
						></v-img>

						<v-card-text :class="['font-weight-bold title white--text']">
							{{ $t(item.name) }}
						</v-card-text>
					</v-card-text>

					<v-card flat rounded align="center">
						<v-card-text class="black--text">
							<div>
								<v-icon
									color="black"
									small
									:left="!$vuetify.rtl"
									:right="$vuetify.rtl"
									>{{ $vuetify.icons.values.phone }}</v-icon
								>
								{{ item.phone }}
							</div>
							<div>
								<v-icon
									color="black"
									small
									:left="!$vuetify.rtl"
									:right="$vuetify.rtl"
									>{{ $vuetify.icons.values.email }}</v-icon
								>
								{{ item.email }}
							</div>
						</v-card-text>
					</v-card>
				</v-card>
			</template>
		</v-container>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "WebsiteGetInTouch",

	computed: {
		...mapGetters({
			userLang: "user/getLanguage",
		}),

		imageWidth() {
			return window.innerWidth < this.$vuetify.breakpoint.thresholds.sm
				? 60
				: 70;
		},

		people() {
			return [
				{
					name: "app.dror_name",
					phone: "052-8390966",
					email: "Info@skillsx.ai",
				},
				// {
				// 	name: "app.eli_name",
				// 	phone: "058-765-8705",
				// 	email: "elih@skillsx.ai",
				// },
			];
		},
	},
};
</script>
